import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import About from "../components/about"


const AboutPage = ({ data }) => {
  const { body, frontmatter } = data.about.edges[0].node
  const {title, emoji, greetings, subtitlePrefix, subtitleHighlight, introduction, about_title} = frontmatter
  return (
    <Layout>
      <About>
        <h1 className="title">
          {greetings}{" "}
          <span role="img" aria-label="emoji">
            {emoji}
          </span>
          <br />
          {title}
        </h1>
        <h2 className="subtitle">
          {subtitlePrefix}{" "}
          <span className="highlighted">{subtitleHighlight}</span>
        </h2>
        <div className="mt-2">
        {introduction}{" "}
        </div>
        <div class="flex flex-wrap justify-center">
          <div className="w-auto px-4 mt-20 mb-10">
            <StaticImage
              class="hero_picture"
              src="../images/its_me_as_avatar.png"
              alt="It's me, Daniel!"
              layout="fixed"
              width={250}
              height={250}
            />
          </div>
          <div>
            <h4 class="text-3xl font-normal leading-normal mt-0 mb-2">
              {about_title}
            </h4>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>

        <h3 class="flex items-center my-8">
          <span aria-hidden="true" class="flex-grow bg-gray-200 rounded h-0.5"></span>
          <span class="text-lg font-medium mx3">Links</span>
          <span aria-hidden="true" class="flex-grow bg-gray-200 rounded h-0.5"></span>
        </h3>
        <div className="flex flex-wrap justify-center">
        <Link to="https://github.com/xFuture603" class="btn_custom">GitHub</Link>
        <Link to="https://www.xfuture-blog.com/" class="btn_custom">Blog</Link>
        <Link to="https://twitter.com/xFuturecs" class="btn_custom">Twitter</Link>
        <Link to="https://www.linkedin.com/in/daniel-uhlmann-38263115b/" class="btn_custom">LinkedIn</Link>
        </div>
      </About>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  {
    about: allMdx(filter: {fileAbsolutePath: {regex: "/about/"}}) {
      edges {
        node {
          body
          frontmatter {
            title
            greetings
            emoji
            subtitlePrefix
            subtitleHighlight
            introduction
            about_title
          }
        }
      }
    }
  }
`
