import React from "react"
import styled from "styled-components"

const StyledSection = styled.section`
  .title {
    margin-bottom: 0;
  }
  .subtitle {
    margin-top: 0;
  }
  .highlighted {
    box-shadow: inset 0 -2.5rem 0 #e25441;
  }
`
const About = ({children}) => {
  return (
    <StyledSection id="about">
      {children}
    </StyledSection>
  )
}

export default About
